var lisTableInstance = null;
$(document).ready(function () {
    XRegExp.install('namespacing');
    $('a.delete-cache').click(function (event) {
        event.preventDefault();
        $.ajax({
            url:         $(event.currentTarget).attr('href'),
            type:        'get',
            ajaxElement: $('body')
        });
    });
    _.mixin(_.str.exports());
    EfbHelper.setTranslations(translations);
    new MediaUploadTool().init();
    lisTableInstance = new ListTable().init({
        url:            urlHelper.getSimpleUrl(),
        browserHistory: true
    });
    var status       = urlHelper.getState();
    if (!_.isUndefined(status.params.is_conflict_clone) && status.params.is_conflict_clone === '1') {
        Conflict.prepareConflictContent();
    }
    $("#navigation").mCustomScrollbar({
        theme:     'minimal-dark',
        scrollInertia: 0
    });
    $('html').trigger('scroll');
});